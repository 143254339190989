<template>
  <div class="cart-thanks">
    <div class="cart-review-title">
      <h1 class="page-title">Thank you for your payment</h1>

      <CartSteps step="collect" />

      <div class="row justify-content-center">
        <div class="col-12 col-md-8">
          <div class="page-content text-center">
            <h3>Soon you will be redirected to collect your items.</h3>
            <p>
              Taking to long? Click <router-link :to="{ name: 'OrderCollect', params: { id: '9182323' }}">here</router-link> to be redirected.
            </p>
          </div>
        </div>

      </div>


    </div>
  </div>



</template>

<script>
import CartSteps from "@/components/CartSteps"
export default {
  name: 'CartThanks',
  metaInfo: {
    title: 'Thank you for your payment',
  },
  components: {
    CartSteps
  },
  data() {
    return {
    }
  },
  methods: {
    onClickCreateAccount() {
      this.$router.push(`/register`);
    }
  }
}
</script>

<style lang="scss">
.cart-thanks {
  .cart-review-title {
    .page-title {
      margin: 6rem 0 3rem;
      font-size: 76px;
      font-weight: 700;
      text-align: center;

      @media screen and (max-width: 770px) {
        margin-top: 15px;
        line-height: 1.0;
      }
    }

  }

  .page-content {
    h3 {
      font-weight: bold;
      font-size: 29px;
      line-height: 1.2;
    }

    p {
      font-size:18px;
      font-weight: 400;
      margin-top: 2rem;
    }
  }
}
</style>
