<template>
  <div class="cart-steps-container">
    <div :class="['cart-step', { done: checkDone }, { active: step == 'check' }]">
      <div class="circle">
        <IconProgressCheck v-if="checkDone" class="color-svg" />
      </div>
      <p>Check items</p>
    </div>
    <div :class="['cart-step', { done: paymentMethodDone }, { active: step == 'payment_method' }]">
      <div class="circle">
        <IconProgressCheck v-if="paymentMethodDone" class="color-svg" />
      </div>
      <p>Payment method</p>
    </div>
    <div :class="['cart-step', { done: payDone }, { active: step == 'pay' }]">
      <div class="circle">
        <IconProgressCheck v-if="payDone" class="color-svg" />
      </div>
      <p>Pay</p>
    </div>
    <div :class="['cart-step', { done: collectDone }, { active: step == 'collect' }]">
      <div class="circle">
        <IconProgressCheck v-if="collectDone" class="color-svg" />
      </div>
      <p>Collect ITEMS</p>
    </div>
  </div>
</template>

<script>
// check
// payment_method
// pay
// collect
import IconProgressCheck from "@/assets/icons/icon-progress-check.svg";
export default {
  name: 'CartSteps',
  props: {
    step: String
  },
  computed: {
    checkDone() {
      return ['payment_method', 'collect', 'finished'].includes(this.step);
    },
    paymentMethodDone() {
      return ['collect'].includes(this.step);
    },
    payDone() {
      return ['collect'].includes(this.step);
    },
    collectDone() {
      return ['finished'].includes(this.step);
    },
  },
  components: {
    IconProgressCheck
  }
}
</script>

<style scoped lang="scss">

</style>
